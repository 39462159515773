<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<app-breadcrumb [path]="'Orçamento'" [showTitle]="true"></app-breadcrumb>

<div class="budget-main" *ngIf="!(isLoading$ | async)">
  <div class="container" id="id-div-budget-print" style="display: none"></div>
  <div class="container" id="id-div-budget-visual">
    <div class="budget-left">
      <div class="budget-left-client">
        <h2>CLIENTE</h2>
        <p>{{ budget?.user?.fullName }} - CPF: {{ budget?.user?.document }}</p>
        <p>
          Telefone: <span> {{ budget?.user?.phone }}</span> - Email:<span>
            {{ budget?.user?.email }}</span
          >
        </p>
        <p>
          Endereço:
          <span> {{ budget?.user?.getAddressDescription() }}</span>
        </p>
      </div>
      <div class="budget-left-products">
        <h2>ORÇAMENTO Nº {{ budget?.id }}</h2>

        <div
          class="budget-left-products-item"
          *ngFor="let item of budget?.cart?.items"
        >
          <div class="budget-left-products-item-img">
            <a href="{{ item?.product?.url }}" target="_blank">
              <img
                [src]="
                  item?.product?.mainPhotoS3Url ||
                  item?.product?.photos?.slice()?.shift()?.url ||
                  ''
                "
                [alt]="item?.product?.name"
                width="100"
                height="100"
              />
            </a>
            <p>
              {{ item?.product?.name }}
              {{
                (item?.product?.discount || 0) > 0
                  ? " - " + item?.product?.discount + "% OFF"
                  : ""
              }}
              <span *ngIf="item?.product?.productPerSquareMeter">
                {{
                  item?.product?.productPerSquareMeter
                    ? "Largura: " +
                      item?.customWidth?.toFixed(2) +
                      "m x Altura: " +
                      item?.customHeight?.toFixed(2) +
                      "m"
                    : ""
                }}
              </span>

              <span *ngIf="item?.product?.isPersiana()">
                {{ item?.driveDescription }}
                | {{ item?.controlSideDescription }}
                {{
                  item?.product?.isPersianaRomana()
                    ? ""
                    : " | " + item?.panelDescription
                }}
              </span>

              <span>
                {{ "Marca: " + item?.product?.brandDescription || "" }} -
                {{ "Código: " + item?.product?.id }}</span
              >
            </p>
          </div>

          <div class="budget-left-products-item-qtd">
            <span>{{ item.amount }}</span>
          </div>

          <div class="budget-left-products-item-value">
            <p
              [ngClass]="{ 'with-discount': (item.product?.discount || 0) > 0 }"
            >
              {{
                getValueItem(item, false) * (item?.amount || 0) | customCurrency
              }}
            </p>
            <p *ngIf="(item.product?.discount || 0) > 0">
              {{
                getValueItem(item, true) * (item?.amount || 0) | customCurrency
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="budget-left-packing">
        <h2>EMBALAGENS</h2>
        <p>
          Volume:
          <span> {{ budget?.volume }}</span>
        </p>
        <p>
          Peso total:
          <span> {{ getTotalWeight() }} kg</span>
        </p>
      </div>
      <div class="budget-left-seller">
        <p>
          Vendedor(a):
          <span> {{ budget?.creatorUser?.fullName }}</span>
        </p>
      </div>
    </div>
    <div class="budget-right">
      <div class="budget-right-delivery">
        <h2>ENTREGA</h2>
        <div class="budget-right-delivery-carrier">
          <p>
            {{ budget?.carrier }}
            <span>{{ budget?.valueFreight | customCurrency }}</span>
          </p>
          <p>
            Prazo de entrega<span>{{ budget?.deliveryTime }} dias úteis</span>
          </p>
        </div>
      </div>

      <div class="budget-right-observations">
        <h2>OBESERVAÇÕES</h2>
        <p>{{ budget?.observations }}</p>
      </div>

      <div class="budget-right-resume">
        <h2>RESUMO</h2>
        <div class="budget-right-resume-value">
          <p>
            Total Produtos
            <span>{{ budget?.cart?.valueSubtotal | customCurrency }}</span>
          </p>
          <p class="discount" *ngIf="budget?.cart?.haveProductDiscount">
            Desconto produtos à vista
            <span>-{{ budget?.cart?.valueDiscount | customCurrency }}</span>
          </p>
          <p class="discount" *ngIf="(budget?.cart?.discountSeller || 0) > 0">
            Desconto Vendedor ({{ budget?.cart?.discountSeller }}%)
            <span
              >-{{ budget?.cart?.valueDiscountSeller | customCurrency }}</span
            >
          </p>
          <p *ngIf="(budget?.cart?.valueFreight || 0) > 0">
            Frete <span>{{ budget?.cart?.valueFreight | customCurrency }}</span>
          </p>
        </div>
      </div>

      <div class="budget-right-payment">
        <h2>OPÇÕES DE PAGAMENTO</h2>
        <p>
          À vista, via PIX
          <span>{{ budget?.cart?.valueTotal | customCurrency }}</span>
        </p>
        <p>
          4x sem juros
          <span>{{
            (budget?.cart?.valueSubtotal || 0) +
              (budget?.cart?.valueFreight || 0) | customCurrency
          }}</span>
        </p>
        <p>
          8x sem juros
          <span>{{ getTotalInstallment(8) | customCurrency }}</span>
        </p>
        <p>
          12x sem juros
          <span>{{ getTotalInstallment(12) | customCurrency }}</span>
        </p>

        <h3>para ver outras opções de pagamento clique em finalizar</h3>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-lock"
          iconPos="right"
          class="button-rm"
          aria-label="Finalizar sua compra"
          label="FINALIZAR SUA COMPRA"
        ></button>

        <p class="budget-right-payment-footer">
          <a target="_blank" href="politicas-trocas-cancelamentos"
            >Trocas, Devoluções e Políticas de Entrega</a
          >
          <a target="_blank" href="termos-condicoes-uso">Termos e Condições</a>
          <a target="_blank" href="aviso-de-privacidade"
            >Aviso de Privacidade</a
          >
          <a target="_blank" href="faq">Perguntas Frequentes</a>
          <a target="_blank" href="fale-conosco">Fale Conosco</a>
        </p>
      </div>
    </div>
  </div>
</div>
