import User from '../user/user';
import Phone from './phone';
export default class Holder {
  public birthDate?: string;
  public documentValue?: string;
  public name?: string;
  public phone?: Phone;

  constructor(user: User) {
    this.birthDate = user?.birthDate?.toString();
    this.documentValue = user?.document?.replace(/\D/g, '');
    this.name = user?.fullName;
    this.phone = new Phone(user?.phone?.toString() || '');
  }
}
