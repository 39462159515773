<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<app-breadcrumb [path]="'Carrinho'" [showTitle]="true"></app-breadcrumb>

<div class="cart-main">
  <div class="container">
    <div class="cart-main-progress" *ngIf="loadingCart">
      <p-progressSpinner
        strokeWidth="3"
        aria-label="Buscando informações dos seus pedidos"
      ></p-progressSpinner>
    </div>

    <app-has-no-itens
      *ngIf="showHasNoItens"
      [attr.hidden]="!showHasNoItens ? true : null"
      [title]="'seu carrinho está vazio!'"
      [labelButton]="'comprar produtos'"
      [route]="'/coberturas-em-policarbonato/policarbonato-alveolar'"
    ></app-has-no-itens>

    <div class="cart-main-content" *ngIf="hasCart() && !loadingCart">
      <div class="cart-main-content-products mobile-invisible">
        <app-cart-products-desktop
          [user]="user"
          [cart]="cart"
          [loadingFreight]="loadingFreight"
          [quotes]="quotes"
          [freightError]="freightError"
          [freightSelected]="freightSelected"
          (onSetQuantity)="setQuantity($event)"
          (onChangeZipCode)="changeZipCode()"
          (onAdressFound)="addressFound($event)"
          (onChangeFreightSelected)="setFreightSelectedDesktop($event)"
          (onClickCheckout)="onClickCheckout()"
          (onShowBudget)="showBudget = true"
          (onDeleteCart)="onClickDeleteCart()"
        ></app-cart-products-desktop>
      </div>
      <div class="cart-main-content-products-mobile mobile-visible">
        <h2>PRODUTOS</h2>
        <app-cart-products-mobile
          [cart]="cart"
          (onSetQuantity)="setQuantity($event)"
          (onRemoveItem)="removeItem($event)"
        ></app-cart-products-mobile>
        <div class="cart-main-content-resume">
          <div class="cart-main-content-resume-freight">
            <h2>FRETE</h2>
            <div class="cart-main-content-resume-freight-zip">
              <app-zip-code-input
                placeholder="Seu CEP"
                (changed)="changeZipCode()"
                (addressFound)="addressFound($event)"
              ></app-zip-code-input>
            </div>
            <p *ngIf="address">{{ address.city + "/" + address.uf }}</p>
            <app-freight-quotes-desktop
              [loadingFreight]="loadingFreight"
              [quotes]="quotes"
              [errorFreight]="freightError"
              [freightSelected]="freightSelected"
              (onChangeFreightSelected)="setFreightSelected($event)"
            ></app-freight-quotes-desktop>
          </div>
          <div class="cart-main-content-resume-order">
            <h2>RESUMO DO PEDIDO</h2>
            <div class="order-subtotal">
              <p>Subtotal</p>
              <p>{{ cart.valueSubtotal | customCurrency }}</p>
            </div>
            <div
              *ngIf="cart.haveProductDiscount"
              class="order-subtotal discount"
            >
              <p>Desconto do produto à vista</p>
              <p>-{{ cart.valueDiscount | customCurrency }}</p>
            </div>
            <div
              *ngIf="(freightSelected?.freight?.priceFreight || 0) > 0"
              class="order-subtotal last"
            >
              <p>Frete</p>
              <p>
                +{{
                  freightSelected?.freight?.priceFreight || 0 | customCurrency
                }}
              </p>
            </div>
            <div class="order-total">
              <p>Total</p>
              <div>
                <p>{{ cart.valueTotal || 0 | customCurrency }} à vista</p>
                <p>
                  {{
                    (cart.valueSubtotal || 0) +
                      (freightSelected?.freight?.priceFreight || 0)
                      | customCurrency
                  }}
                  em 4x sem juros
                </p>

                <p>ou em até 12x vezes</p>
              </div>
            </div>

            <div class="order-checkout">
              <p>cupons e outras opções de pagamentos na próxima etapa</p>
              <div class="mobile-invisible">
                <a href="/pronta-entrega/blackout">
                  <button
                    title="Continuar comprando"
                    class="button-rm"
                    aria-label="Continuar comprando"
                  >
                    CONTINUAR COMPRANDO
                  </button></a
                ><button
                  (click)="onClickCheckout()"
                  title="Finalizar sua compra"
                  class="button-rm"
                  aria-label="Finalizar sua compra"
                >
                  FINALIZAR SUA COMPRA
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="cart-footer-mobile mobile-visible">
    <a href="/rolo/todos-modelos">
      <button
        title="Continuar comprando"
        class="button-rm"
        aria-label="Continuar comprando"
      >
        CONTINUAR COMPRANDO
      </button></a
    ><button
      (click)="onClickCheckout()"
      title="Finalizar sua compra"
      class="button-rm"
      aria-label="Finalizar sua compra"
    >
      FINALIZAR SUA COMPRA
    </button>
  </div>

  <p-dialog
    header="Preencha os dados"
    [visible]="showBudget"
    [modal]="true"
    [style]="{ width: '25vw', height: '45vh' }"
    [draggable]="false"
    [resizable]="false"
    (onHide)="showBudget = false"
    [(visible)]="showBudget"
  >
    <form action="">
      <input
        type="text"
        placeholder="Informe o nome"
        required
        name="name"
        pInputText
        [(ngModel)]="budgetData.name"
        [ngClass]="{
          'error-input': budgetError.errorName
        }"
      />
      <p-inputMask
        mask="(99) 99999-9999"
        name="telefone"
        [(ngModel)]="budgetData.phone"
        placeholder="Informe o telefone com DDD"
        [ngClass]="{
          'error-input-mask': budgetError.errorPhone
        }"
      ></p-inputMask>

      <p-inputMask
        name="zipCode"
        mask="99999-999"
        type="text"
        [(ngModel)]="zipCode"
        [ngClass]="{
          'error-input-mask': !zipCode
        }"
      ></p-inputMask>

      <button
        class="button-rm"
        aria-label="Gerar orçamento"
        type="submit"
        (click)="onClickBtCreateBudget()"
      >
        GERAR ORÇAMENTO
      </button>
    </form>
  </p-dialog>
</div>
