import User from '../user/user';
import Phone from './phone';

export default class PaymentSender {
  public documentType?: string; // CPF, CNPJ, OTHER
  public documentValue?: string;
  public email?: string;
  public hash?: string;
  public name?: string;
  public phone?: Phone;
  public acceptNewsletter = false;

  constructor(user: User, hash: string, acceptNewsletter: boolean) {
    this.documentType = 'CPF';
    this.documentValue = user?.document?.replace(/\D/g, '');
    this.email = user?.email;
    this.name = user.getFullName();
    this.phone = new Phone(user?.phone?.toString() || '');
    this.hash = hash;
    this.acceptNewsletter = acceptNewsletter;
  }
}
