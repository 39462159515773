import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT, isPlatformBrowser, NgClass } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { PrimeNGModule } from '../../../primeng.module';
import { CartService } from '../../../services/cart/cart.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import {
  ProductSearch,
  ProductsService,
} from '../../../services/product/products.service';
import { ToastService } from '../../../services/toast/toast.service';
import { WhatsAppService } from '../../../services/whats-app/whats-app.service';
import { AbstractInfoEnum } from '../../models/app/abstract-info-enum';
import { InfoCategory } from '../../models/app/info-category';
import { CategoriesEnum, SubCategoriesEnum } from '../../models/app/info-enum';
import { CartItem } from '../../models/cart/cart';
import { PageResponse } from '../../models/page/page-response';
import InfoSubCategory from '../../models/product/info-sub-category';
import { Product } from '../../models/product/product';
import ProductFilter from '../../models/product/product-filter';
import { SharedModule } from '../../shared.module';
import {
  ProductShopOrder,
  ProductsShopOrderComponent,
} from '../products-shop-order/products-shop-order.component';

export class FilterProductsShop {
  public filterSelected?: AbstractInfoEnum;
  public name?: string;
  public title?: string;
  public values?: FilterValueProductsShop[];
  public visible = true;
}

export class FilterValueProductsShop {
  public description?: string;
  public id?: string;
  public title?: string;
}
export class FilterAttributes {
  public actuationSide?: AbstractInfoEnum;
  public actuationType?: AbstractInfoEnum;
  public aluminumWallThickness?: AbstractInfoEnum;
  public brand?: AbstractInfoEnum;
  public capabilitie?: AbstractInfoEnum;
  public color?: AbstractInfoEnum;
  public colorCode?: AbstractInfoEnum;
  public fabricComposition?: AbstractInfoEnum;
  public finish?: AbstractInfoEnum;
  public heightThickness?: AbstractInfoEnum;
  public length?: AbstractInfoEnum;
  public model?: AbstractInfoEnum;
  public potencie?: AbstractInfoEnum;
  public speed?: AbstractInfoEnum;
  public unitsCommercialize?: AbstractInfoEnum;
  public voltage?: AbstractInfoEnum;
  public width?: AbstractInfoEnum;
}

export class ConfigCategories {
  public categories?: Array<InfoCategory>;
  public categorySelected: InfoCategory | undefined;
  public subCategorySelected: InfoSubCategory | undefined;
  public productsOnOffer: boolean = false;
  public productsQuerySearch: string | undefined;
}

@Component({
  selector: 'app-products-shop',
  standalone: true,
  imports: [NgClass, PrimeNGModule, ProductsShopOrderComponent, SharedModule],
  templateUrl: './products-shop.component.html',
  styleUrl: './products-shop.component.scss',
  providers: [ToastService, MessageService],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProductsShopComponent implements OnInit, OnDestroy {
  @Input()
  public set configCategories(configCategories: ConfigCategories | undefined) {
    if (configCategories) {
      this.loaderService.show();
      this.categories = configCategories.categories || [];
      this.categorySelected = configCategories.categorySelected;
      this.subCategorySelected = configCategories.subCategorySelected;
      this.productsOnOffer = configCategories.productsOnOffer;
      this.productsQuerySearch = configCategories.productsQuerySearch;
      this.setResize();
      if (this.productsOnOffer) {
        this.configProductOnOffer();
      } else if (this.productsQuerySearch) {
        this.configProductQuerySearch();
      } else {
        this.configFilterProductShop();
      }
    }
  }

  private destroy$ = new Subject<void>();

  public categories?: Array<InfoCategory>;
  public categorySelected: InfoCategory | undefined;
  public filterProductsShop: FilterProductsShop[] = [];
  public page: number = 0;
  public pageResponse: PageResponse | undefined;
  public products: Array<Product> = [];
  public productFilter: ProductFilter = new ProductFilter();
  public productsQuerySearch: string | undefined;
  public productsOnOffer: boolean = false;
  public showFilterMobile = true;
  public isMobile = false;
  public subCategorySelected: InfoSubCategory | undefined;

  constructor(
    @Inject(DOCUMENT) private dom: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cartService: CartService,
    private metaTagService: MetaTagsService,
    private productsService: ProductsService,
    public router: Router,
    private toastService: ToastService,
    private whatsAppService: WhatsAppService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.productsService
      .getSearchProductsSubCategory()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (productSearch: ProductSearch) => {
        if (productSearch?.route) {
          this.productFilter.categories = [
            InfoCategory.formatCategoryToUpperSnake(productSearch.route),
          ];
          this.productFilter.querySearch = productSearch.search;
          this.getProducts();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  configFilterProductShop() {
    this.filterProductsShop = [];
    let filter: FilterProductsShop | undefined =
      this.subCategorySelected?.getFilterSubCategoryByName('models');
    if (filter) {
      this.filterProductsShop.push(filter);
    }

    filter = this.subCategorySelected?.getFilterSubCategoryByName('widths');
    if (filter) {
      this.filterProductsShop.push(filter);
    }

    filter =
      this.subCategorySelected?.getFilterSubCategoryByName('heightThickness');
    if (filter) {
      this.filterProductsShop.push(filter);
    }

    filter = this.subCategorySelected?.getFilterSubCategoryByName('lengths');
    if (filter) {
      this.filterProductsShop.push(filter);
    }

    filter = this.subCategorySelected?.getFilterSubCategoryByName('finishes');
    if (filter) {
      this.filterProductsShop.push(filter);
    }

    filter = this.subCategorySelected?.getFilterSubCategoryByName('colors');
    if (filter) {
      this.filterProductsShop.push(filter);
    }

    filter =
      this.subCategorySelected?.getFilterSubCategoryByName('capabilities');
    if (filter) {
      this.filterProductsShop.push(filter);
    }

    filter = this.subCategorySelected?.getFilterSubCategoryByName('potencies');
    if (filter) {
      this.filterProductsShop.push(filter);
    }

    filter = this.subCategorySelected?.getFilterSubCategoryByName('speeds');
    if (filter) {
      this.filterProductsShop.push(filter);
    }

    filter = this.subCategorySelected?.getFilterSubCategoryByName('voltages');
    if (filter) {
      this.filterProductsShop.push(filter);
    }
    this.configFilterProducts();
  }

  configProductOnOffer() {
    this.productFilter.withDiscountOrFeatured = true;
    this.productFilter.discount = 5;
    this.getProducts();
  }

  configProductQuerySearch() {
    if (this.isBiggestDiscount()) {
      this.productFilter.withDiscountOrFeatured = true;
      this.productFilter.direction = 'DESC';
      this.productFilter.categories = [
        CategoriesEnum.ACESSORIOS_DE_INSTALACAO,
        CategoriesEnum.COBERTURAS_EM_POLICARBONATO,
        CategoriesEnum.DECORACAO,
        CategoriesEnum.KITS,
        CategoriesEnum.PERFIS_EM_ALUMINIO,
        CategoriesEnum.TOLDOS,
      ];
    } else {
      this.productFilter.querySearch = this.productsQuerySearch;
    }
    this.getProducts();
  }

  isBiggestDiscount(): boolean {
    return this.productsQuerySearch === 'maiores-descontos';
  }
  configFilterProducts() {
    this.productFilter.categories = [this.categorySelected?.id || ''];
    this.productFilter.subCategories = [this.subCategorySelected?.id || ''];
    this.getProducts();
  }

  async getProducts() {
    this.showFilterMobile = false;
    this.toTop();
    try {
      if (!this.productFilter.direction) {
        this.productFilter.direction = 'ASC';
      }

      this.productFilter.perPage = 48;
      this.productFilter.order = this.isBiggestDiscount()
        ? ['discount']
        : ['value'];
      this.productFilter.page = this.page;
      this.productFilter.inactive = false;
      this.productFilter.productPerSquareMeter =
        this.subCategoryPerSquareMeter();
      this.productFilter.ignoreMainVariation = this.haveFilterToVariation();
      this.products = [];
      this.pageResponse = await this.productsService.products(
        this.productFilter
      );

      if ((this.pageResponse?.totalElements || 0) > 0) {
        this.products = this.pageResponse?.content || [];
        this.products = this.products.filter((p) => this.showProduct(p));
        this.setAriaLabel();
      }
    } catch (err) {
      this.toastService.error('Não existe produto para o filtro selecionado!');
    } finally {
      this.loaderService.hide();
    }
  }

  haveFilterToVariation() {
    return true;
  }

  subCategoryPerSquareMeter() {
    return (
      this.subCategorySelected?.id === SubCategoriesEnum.TOLDOS_CORTINA ||
      this.subCategorySelected?.id === SubCategoriesEnum.ROLO ||
      this.subCategorySelected?.id === SubCategoriesEnum.DOUBLE_VISION ||
      this.subCategorySelected?.id === SubCategoriesEnum.ROMANA ||
      this.subCategorySelected?.id === SubCategoriesEnum.ROMANA_DE_TETO ||
      this.subCategorySelected?.id === SubCategoriesEnum.HORIZONTAL ||
      this.subCategorySelected?.id === SubCategoriesEnum.PAINEL
    );
  }

  private showProduct(prod: Product): boolean {
    let show = prod.enabled;
    if (
      prod.stockBalance === 0 &&
      (prod.daysOnlyOrder === 0 || !prod.daysOnlyOrder)
    ) {
      show = false;
    }
    return show;
  }

  onClickOpenProduct(url: string | undefined) {
    if (url) {
      this.router.navigate([url]);
    }
  }

  navigateToProduct(url: string) {
    this.router.navigateByUrl(url);
  }

  async onClickAddToCart(product: Product | undefined, payment: boolean) {
    if (product) {
      if (product.isPersiana()) {
        return this.onClickOpenProduct(product.url);
      }
      try {
        if (!product.enabled) {
          return this.toastService.error(
            `Produto ${product.id} não pode ser adicionado ao seu carrinho pois está inativo!`
          );
        }

        if (
          product.qtd > product.stockBalance &&
          !product.canBeGeneratedWithStockZero
        ) {
          return this.toastService.error(
            `Existe apenas ${product.stockBalance} item(s) disponível(is)`
          );
        }

        const cartItem: CartItem = {
          idProduct: product.id,
          amount: product.qtd || 1,
          product,
        };

        setTimeout(async () => {
          try {
            await this.cartService.addItem(cartItem, true, true);
            if (payment) {
              this.router.navigate(['pagamento']);
            } else {
              this.toastService.success(
                'Produto adicionado ao carrinho com sucesso!',
                2000
              );
            }
          } catch (error: any) {
            this.toastService.error(error);
          }
        }, 500);
      } catch (error) {
        this.toastService.error(
          'Ocorreu um erro ao tentar adicionar seu produto ao carrinho!'
        );
      }
    }
  }

  onClickBuyByWhatss(product: Product) {
    if (product) {
      this.whatsAppService.setOpenWhatsAppProduct(product);
    }
  }

  onClickSubCategory() {
    this.page = 0;
    const subSearch = this.productFilter?.subCategories?.slice().shift();
    if (subSearch !== this.subCategorySelected?.id) {
      const path = this.subCategorySelected?.path;
      if (path) {
        this.productFilter = new ProductFilter();
        this.router.navigate([path]);
      }
    }
  }

  onClickFilter(filter: FilterProductsShop) {
    this.page = 0;
    const name = filter.name || '';
    const value = filter?.filterSelected?.id || '';
    const filterProduct = Object.getOwnPropertyDescriptors(this.productFilter);
    const prop = filterProduct[name];
    if (prop?.value !== value) {
      this.productFilter.setValue(name, value);
      this.getProducts();
    } else {
      filter.filterSelected = undefined;
      this.productFilter.setValue(name, '');
      this.getProducts();
    }
  }

  isKit(product: Product): boolean {
    return product.productType === 2;
  }

  onPageChange(event: any) {
    this.page = event.page;
    this.getProducts();
  }

  showPagination() {
    return (
      (this.products?.length || 0) > 0 && !this.subCategoryPerSquareMeter()
    );
  }

  toTop() {
    this.dom.body.scrollTop = 0; // Safari
    this.dom.documentElement.scrollTop = 0; // Other browsers
  }

  changedOrderProducts(filter: ProductShopOrder) {
    this.productFilter.direction = '';
    if (filter) {
      this.productFilter.direction = filter.orderBy;
    }
    this.getProducts();
  }

  addOverflowHiddenToBody(add: boolean) {
    this.metaTagService.classToBody('overflow-hidden', add);
  }

  setAriaLabel() {
    setTimeout(() => {
      var element = this.dom.getElementsByClassName('p-paginator-first');
      if (element.length > 0) {
        element[0].ariaLabel = 'Primeira página';
      }

      element = this.dom.getElementsByClassName('p-paginator-prev');
      if (element.length > 0) {
        element[0].ariaLabel = 'Página anterior';
      }

      element = this.dom.getElementsByClassName('p-paginator-next');
      if (element.length > 0) {
        element[0].ariaLabel = 'Próxima página';
      }

      element = this.dom.getElementsByClassName('p-paginator-last');
      if (element.length > 0) {
        element[0].ariaLabel = 'Última página';
      }
    }, 500);
  }

  setResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window?.innerWidth < 640;
    }
  }

  hasProducts() {
    return this.products.length > 0;
  }
}
