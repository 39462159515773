import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { InfoCategory } from '../../shared/models/app/info-category';
import { CategoriesEnum } from '../../shared/models/app/info-enum';
import { Product } from '../../shared/models/product/product';
import { AppInfoService } from '../app-info/app-info.service';

export class MetaTagsData {
  public description: string = '';
  public h1: string = '';
  public h2: string = '';
  public title: string = '';
  public urlImage: string = '';
}

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  public static readonly PAGE_ABOUT = 'page-about';
  public static readonly PAGE_BUDGET = 'page-budget';
  public static readonly PAGE_CART = 'page-cart';
  public static readonly PAGE_CATALOG = 'page-catalog';
  public static readonly PAGE_CATEGORIES = 'page-categories';
  public static readonly PAGE_CONTACT = 'page-contact';
  public static readonly PAGE_CUSTOMER_AREA = 'page-customer-area';
  public static readonly PAGE_CUSTOMER_AREA_ADDRESS =
    'page-customer-area-address';
  public static readonly PAGE_CUSTOMER_AREA_DATA = 'page-customer-area-data';
  public static readonly PAGE_CUSTOMER_AREA_ORDERS =
    'page-customer-area-orders';
  public static readonly PAGE_DEALER = 'page-dealer';
  public static readonly PAGE_FAQ = 'page-faq';
  public static readonly PAGE_FORGOT = 'page-forgot';
  public static readonly PAGE_HOME = 'page-home';
  public static readonly PAGE_LOGIN = 'page-login';
  public static readonly PAGE_NOT_FOUND = 'page-not-found';
  public static readonly PAGE_PAYMENT = 'page-payment';
  public static readonly PAGE_POLICIES = 'page-policies';
  public static readonly PAGE_PRIVACY = 'page-privacy';
  public static readonly PAGE_PRODUCTS = 'page-products';
  public static readonly PAGE_REGISTER = 'page-register';
  public static readonly PAGE_SAC = 'page-sac';
  public static readonly PAGE_SUB_CATEGORIES = 'page-sub-categories';
  public static readonly PAGE_TERMS = 'page-terms';

  public static readonly TAG_DESCRIPTION = 'description';
  public static readonly TAG_OG_DESCRIPTION = 'og:description';
  public static readonly TAG_OG_IMAGE = 'og:image';

  public categories: Array<InfoCategory> = [];
  private renderer: Renderer2;

  constructor(
    private appInfoService: AppInfoService,
    private meta: Meta,
    public title: Title,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private dom: any
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.appInfoService.getCompleteAppInfo().subscribe(async (data) => {
      if (data && this.categories?.length === 0) {
        this.categories =
          await this.appInfoService.getCategoriesWithProductActive();
      }
    });
  }

  addTag(tag: MetaDefinition) {
    this.meta.addTag(tag);
  }

  addTags(tags: MetaDefinition[]) {
    this.meta.addTags(tags);
  }

  getTag(tags: string) {
    return this.meta.getTag(tags);
  }

  updateTagByName(keyTag: string, value: string) {
    const metaTag = this.getTag(`name='${keyTag}'`);
    if (metaTag) {
      metaTag.content = value;
    } else {
      this.addTag({ name: keyTag, content: value });
    }
  }

  updateTagByProperty(keyTag: string, value: string) {
    const metaTag = this.getTag(`property='${keyTag}'`);
    if (metaTag) {
      metaTag.content = value;
    } else {
      this.addTag({ name: keyTag, content: value });
    }
  }

  removeTag(tag: string) {
    const tagDOM = this.meta.getTag(tag);
    if (tagDOM) {
      this.meta.removeTagElement(tagDOM);
    }
    this.meta.removeTag(tag);
  }

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  updateCanonicalUrl(urlPath: string, category: string, subCategory: string) {
    let url = '';
    if (subCategory) {
      url = subCategory + '/' + urlPath;
    } else if (category) {
      url = category + '/' + urlPath;
    } else {
      url = urlPath;
    }

    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', 'https://www.rmpolicarbonatos.com.br/' + url);
  }

  configTagPages(
    page: string,
    path: string,
    category: string,
    subCategory: string,
    product: Product | string | null
  ): MetaTagsData {
    const metaData: MetaTagsData = this.getMetaTagsData(
      page,
      path,
      category,
      subCategory,
      product
    );
    this.setTitle(metaData.title);
    this.updateCanonicalUrl(path, category, subCategory);
    this.updateTagByName(MetaTagsService.TAG_DESCRIPTION, metaData.description);
    this.updateTagByProperty(
      MetaTagsService.TAG_OG_DESCRIPTION,
      metaData.description
    );
    this.updateTagByProperty(MetaTagsService.TAG_OG_IMAGE, metaData.urlImage);
    return metaData;
  }

  getMetaTagsData(
    page: string,
    path: string,
    category: string,
    subCategory: string,
    product: Product | string | null
  ): MetaTagsData {
    const home: MetaTagsData = {
      description:
        'Loja online de chapas de policarbonato alveolar e compacto, kits para cobertura de pergolados, toldo cortina, toldo retrátil e toldo articulado, persiana rolo, romana e muito mais para construções e reformas.',
      h1: 'RM Policarbonatos e Acessórios',
      h2: '',
      title: 'RM Policarbonatos | Chapas, Toldos, Persianas e muito mais!',
      urlImage:
        'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
    };

    switch (page) {
      case MetaTagsService.PAGE_ABOUT:
        return {
          description:
            'A RM Policarbonatos é uma empresa Brasileira, que atua com venda online e presencial e acredita que a venda de materiais pode ser fácil, descomplicada e segura. Presta suporte aos clientes desde o pedido até a instalação.',
          h1: 'Quem somos',
          h2: '',
          title: 'Conheça a empresa - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_CART:
        return {
          description:
            'Confirma os itens do seu carrinho e prossiga para o checkout para concluir sua compra agora mesmo na RM Policarbonatos e Acessórios.',
          h1: 'Carrinho de compras',
          h2: '',
          title: 'Carrinho de compras - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_BUDGET:
        return {
          description:
            'Confirma os itens do seu orçamento e prossiga para o checkout para concluir sua compra agora mesmo na Ambientá Brasil - Persianas.',
          h1: 'Orçamento Personalizado',
          h2: '',
          title: 'Orçamento Personalizado - Ambientá Brasil - Persianas',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/ambienta-icon.png',
        };

      case MetaTagsService.PAGE_CATALOG:
        return {
          description:
            'Baixe os catálogos em PDF dos nossos e saiba o passo a passo de instalação e garantia. Tenha acesso às informações detalhadas dos produtos e confie na qualidade da RM Policarbonatos e acessórios.',
          h1: 'Catálogos e garantias',
          h2: '',
          title: 'Catálogos e Garantias - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_CATEGORIES:
        return this.getMetaTagPageCategory(path);

      case MetaTagsService.PAGE_FORGOT:
        return {
          description:
            'Redefina uma nova senha para conntinuar tendo acesso à todas as nossa promoções ',
          h1: 'Redefinir senha',
          h2: '',
          title: 'Redefinir Senha - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_CONTACT:
        return {
          description:
            'Entre em contato conosco através do formulário de contato ou whatsapp 48 3369-7157. Tire suas dúvidas, solicite suporte. Nosso time de vendas está pronto para te ajudar aqui na RM Policarbonatos e Acessórios. ',
          h1: 'Fale Conosco',
          h2: '',
          title: 'Entre em contato com a loja - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_SAC:
        return {
          description:
            'Entre em contato com o SAC através do formulário de contato. Tire suas dúvidas, solicite suporte. Nosso time de vendas está pronto para te ajudar aqui na RM Policarbonatos e Acessórios. ',
          h1: 'SAC - Serviço de Atendimento ao Cliente',
          h2: '',
          title: 'Entre em contato com o SAC - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_FAQ:
        return {
          description:
            'Lista das perguntas mais frequentes. Tire suas dúvidas, e se não encontrar o que procura, solicite suporte. Nosso time de suporte está pronto para te ajudar aqui na RM Policarbonatos e Acessórios. ',
          h1: 'FAQ - Perguntas Frequentes',
          h2: '',
          title: 'Perguntas Frequentes - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_DEALER:
        return {
          description:
            'Entre em contato através do formulário para se tornar um revendedor da RM Policarbonatos e Acessórios. ',
          h1: 'Seja um Revendedor',
          h2: '',
          title: 'Seja um Revendedor - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_CUSTOMER_AREA:
        return {
          description:
            'Acesse seus pedidos, seus dados de cadastro e seu endereço.',
          h1: 'Área do Cliente',
          h2: '',
          title: 'Área do Cliente - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_CUSTOMER_AREA_ADDRESS:
        return {
          description: 'Confira e altere seu endereço.',
          h1: 'Meu Endereço',
          h2: '',
          title: 'Meu Endereço - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_CUSTOMER_AREA_DATA:
        return {
          description: 'Confira e altere todos os seus dados pessoais.',
          h1: 'Meus Dados Pessoais',
          h2: '',
          title: 'Meus Dados Pessoais - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_CUSTOMER_AREA_ORDERS:
        return {
          description: 'Confira todos os dados sobre todos os seus pedidos.',
          h1: 'Meus Pedidos',
          h2: '',
          title: 'Meus Pedidos - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_HOME:
        return home;

      case MetaTagsService.PAGE_LOGIN:
        return {
          description: 'Acesse seu painel para ter acesso aos seus pedidos.',
          h1: 'Faça seu login',
          h2: '',
          title: 'Login - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_REGISTER:
        return {
          description:
            'Preenca todos os campos corretamente e tenhhha acesso aos nossso sprodutos e promoções.',
          h1: 'Realize seu Cadastro',
          h2: '',
          title: 'Novo Cadastro - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_NOT_FOUND:
        return {
          description:
            'A página que você tentou acessar não está disponível em nosso sistema.',
          h1: 'Página não encontrada',
          h2: '',
          title: 'Página não encontrada - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_PAYMENT:
        return {
          description:
            'Prossiga para o checkout seguro em nosso e-commerce. Insira suas informações de pagamento e endereço de entrega com tranquilidade, sabendo que seus dados estão protegidos. Garantimos uma experiência de compra segura e confiável.',
          h1: 'Checkout',
          h2: '',
          title: 'Chekout Seguro - RM Policarbonatos e Acessórios',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_POLICIES:
        return {
          description:
            'Conheça as políticas de troca e cancelamento da RM Policarbonatos e Acessórios e faça sua compra com segurança. Estamos comprometidos em garantir a sua satisfação e oferecer uma experiência de troca tranquila e transparente.',
          h1: 'Políticas de troca e cancelamento',
          h2: '',
          title: 'Política de troca, cancelamento - RM Policarbonatos',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_PRIVACY:
        return {
          description:
            'Leia nossa política de privacidade para entender como coletamos, usamos e protegemos seus dados pessoais durante sua visita ao nosso site. Respeitamos sua privacidade e nos comprometemos a manter suas informações seguras e confidenciais. ',
          h1: 'Aviso de privacidade',
          h2: '',
          title: 'Aviso de privacidade - RM Policarbonatos',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };

      case MetaTagsService.PAGE_PRODUCTS:
        return this.getMetaTagPageProducts(category, subCategory, product);

      case MetaTagsService.PAGE_SUB_CATEGORIES:
        return this.getMetaTagPageSubCategory(category, path);

      case MetaTagsService.PAGE_TERMS:
        return {
          description:
            'Conheça os termos e condições de uso a RM Policarbonatos e Acessórios e faça sua compra com segurança. Ao continuar navegando, você concorda com nossos termos e condições.',
          h1: 'Termos de Condições e Uso',
          h2: '',
          title: 'Termos e condições de uso - RM Policarbonatos',
          urlImage:
            'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg',
        };
      default:
        return home;
    }
  }

  getMetaTagPageCategory(path: string): MetaTagsData {
    const meta: MetaTagsData = new MetaTagsData();
    const formatPath = path?.toUpperCase()?.replaceAll('-', '_');
    if (formatPath === CategoriesEnum.PRODUCTS_ON_OFFER_PT) {
      meta.description =
        'Confira todos os nossos produtos em oferta e aproveite nossas promoções!';
      meta.h1 = 'Produtos em Oferta';
      meta.title = 'Produtos em Oferta - RM Policarbonatos e Acessórios';
    } else {
      meta.description = this.getDescriptionPageCategory(path);
      meta.h1 = this.getH1PageCategory(path);
      meta.title =
        this.getTitlePageCategory(path) + ' - RM Policarbonatos e Acessórios';
      meta.urlImage =
        'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg';
    }
    return meta;
  }

  getMetaTagPageSubCategory(category: string, path: string): MetaTagsData {
    const meta: MetaTagsData = new MetaTagsData();
    meta.description = this.getDescriptionPageSubCategory(category, path);
    meta.h1 = this.getH1PageSubCategory(category, path);
    meta.h2 = this.getH2PageSubCategory(category, path);
    meta.title =
      this.getTitlePageSubCategory(category, path) +
      ' - RM Policarbonatos e Acessórios';
    meta.urlImage =
      'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg';
    return meta;
  }

  getMetaTagPageProducts(
    category: string,
    subCategory: string,
    product: Product | string | null
  ): MetaTagsData {
    const meta: MetaTagsData = new MetaTagsData();
    let title = '';

    if (product instanceof Product) {
      title = product?.name || '';
      meta.urlImage =
        product?.coverPhoto ||
        'https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-icon.jpg';
      const session = Product.getSessionByTitle(product as Product, 'Sobre');
      let description = (session?.description || '').replace(
        /(<([^>]+)>)/gi,
        ''
      );
      description = description
        ?.replaceAll('&nbsp;', ' ')
        ?.replaceAll('&nbsp', ' ');
      meta.description = description?.substring(0, 160) + 'SKU: ' + product?.id;
    } else {
      title = ('Buscar Produtos: ' + product) as string;
      meta.description =
        'Policarbonatos em promoção! Escolha entre diversos modelos e marcas. Alveolar, compacto e muito mais. Escolha a sua com preço baixo e entrega garantida!';
    }

    const sku = (product as Product)?.id || '';
    meta.title = title?.substring(0, 40) + ' - RM Policarbonatos - ' + sku;

    if (category) {
      meta.h1 = this.getH1PageProducts(category);
      meta.h2 = this.getH2PageProducts(category, subCategory);
    } else {
      meta.h1 = 'Buscar Produtos: ' + title;
      meta.h2 = 'Busca de Produtos: ' + title;
    }

    return meta;
  }

  getDescriptionPageCategory(path: string) {
    return 'Descrição da Categoria: ' + this.getTitlePageCategory(path);
  }

  getDescriptionPageSubCategory(category: string, subCategory: string) {
    return (
      'Descrição da Subcategoria: ' +
      this.getTitlePageSubCategory(category, subCategory)
    );
  }

  getH1PageCategory(path: string) {
    return 'H1 da Categoria: ' + this.getTitlePageCategory(path);
  }

  getH1PageSubCategory(category: string, subCategory: string) {
    return (
      'H1 da Subcategoria: ' +
      this.getTitlePageSubCategory(category, subCategory)
    );
  }

  getH1PageProducts(category: string) {
    return this.getTitlePageCategory(category);
  }

  getH2PageProducts(category: string, subCategory: string) {
    return this.getTitlePageSubCategory(category, subCategory);
  }

  getH2PageSubCategory(category: string, subCategory: string) {
    return (
      'H2 da Subcategoria: ' +
      this.getTitlePageSubCategory(category, subCategory)
    );
  }

  getTitlePageCategory(path: string) {
    path = path?.toUpperCase()?.replaceAll('-', '_');
    if (path === CategoriesEnum.PRODUCTS_ON_OFFER_PT) {
      return 'Produtos em Oferta';
    } else {
      const category = this.categories.find((c) => c.id === path);
      if (category) {
        return category.description || ' RM Policarbonatos e Acessórios';
      }
    }
    return '';
  }

  getTitlePageSubCategory(category: string, subCategory: string) {
    category = category?.toUpperCase()?.replaceAll('-', '_');
    subCategory = subCategory?.toUpperCase()?.replaceAll('-', '_');

    const categoryInfo = this.categories.find((c) => c.id === category);
    if (categoryInfo) {
      const subCategoryInfo = categoryInfo?.subCategories?.find(
        (s) => s.id === subCategory
      );
      if (subCategoryInfo) {
        return subCategoryInfo.description || 'RM Policarbonatos e Acessórios';
      }
    }
    return 'RM Policarbonatos e Acessórios';
  }

  classToBody(classes: string, add: boolean) {
    if (add) {
      this.renderer.addClass(this.dom.body, classes);
    } else {
      this.renderer.removeClass(this.dom.body, classes);
    }
  }
}
