<div class="products-infos-zipcode">
  <div class="products-infos-zipcode-header">
    <p>Informe seu CEP para calcular o frete</p>
  </div>
  <div class="products-infos-zipcode-content">
    <div class="products-infos-zipcode-content-inputs">
      <input
        class="products-infos-zipcode-quantity"
        pInputText
        type="number"
        [value]="quantitySelected"
        (change)="setQuantitySelected($event)"
        name="quantitySelected"
        aria-label="Alterar quantidade do produto"
        [min]="0"
        [max]="100"
      />

      <app-zip-code-input
        placeholder="Seu CEP"
        (changed)="changeZipCode()"
        (zipcodeError)="zipCodeError()"
        (addressFound)="addressFound($event)"
        [showKnowZipCoded]="false"
        [emitterEventError]="true"
      ></app-zip-code-input>
    </div>

    <p-progressSpinner
      strokeWidth="3"
      *ngIf="loadingFreight"
      aria-label="Buscando informações de frete"
    ></p-progressSpinner>

    <div
      class="products-infos-zipcode-content-quote"
      *ngIf="quotes?.carriers?.length || 0 > 0"
    >
      <p>
        <span>{{ getCarrier(1).displayName?.toLowerCase() }}</span> -
        {{ getCarrier(1).deliveryTime }}
        {{ getCarrier(1).deliveryTime === 1 ? "dia" + " útil" : "dias úteis" }}
        <span *ngIf="getCarrier(1).priceFreight || 0 > 0">
          - {{ getCarrier(1).priceFreight | customCurrency }}</span
        >
        - {{ address?.city + "/" + address?.uf }}
      </p>

      <p>
        ou retire na loja em {{ getCarrier(0).deliveryTime }}
        {{ getCarrier(0).deliveryTime === 1 ? "dia" + " útil" : "dias úteis" }}
        sem custos!
      </p>

      <p
        class="products-infos-zipcode-content-quote-free"
        *ngIf="(getCarrier(1)?.priceFreight || 0) <= 0"
      >
        FRETE GRÁTIS PARA ESSE CEP
      </p>
    </div>
  </div>
</div>
